var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "message-box dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickOutSide.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("header", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "dialog-body" }, [
          _c("span", { staticClass: "message" }, [_vm._v(_vm._s(_vm.content))]),
          _c(
            "div",
            { staticClass: "folder-list" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "folder-list-item pointer",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onCheck(item)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/icon/folder_black.png"),
                    },
                  }),
                  _c("span", { staticClass: "flex-1 ellipsis" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm.checked(item)
                    ? _c("checkbox-marked-outline-icon", {
                        staticClass: "center pointer px-8",
                      })
                    : _c("checkbox-blank-outline-icon", {
                        staticClass: "center pointer px-8",
                      }),
                ],
                1
              )
            }),
            0
          ),
        ]),
        _c("footer", [
          _c("div", { staticClass: "button-area" }, [
            _c(
              "button",
              {
                staticClass: "button cancel",
                on: { click: _vm.onNegativeClick },
              },
              [_vm._v(_vm._s(_vm.negative))]
            ),
            _c(
              "button",
              {
                staticClass: "button save",
                on: { click: _vm.onPositiveClick },
              },
              [_vm._v(_vm._s(_vm.positive))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }