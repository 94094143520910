var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tile", on: { click: _vm.onClick } },
    [
      _c("list-tile", [
        _c(
          "div",
          {
            staticClass: "align-center leading",
            attrs: { slot: "leading" },
            slot: "leading",
          },
          [
            _vm.isDeleteEditing
              ? _c(
                  "div",
                  { staticClass: "delete" },
                  [
                    _vm.checked
                      ? _c("checkbox-marked-outline-icon", {
                          staticClass: "center pointer",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCheck.apply(null, arguments)
                            },
                          },
                        })
                      : _c("checkbox-blank-outline-icon", {
                          staticClass: "center pointer",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.onCheck.apply(null, arguments)
                            },
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _c("img", {
              attrs: { src: require("@/assets/img/icon/folder_black.png") },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "align-center content",
            attrs: { slot: "content" },
            slot: "content",
          },
          [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isListEditing,
                    expression: "!isListEditing",
                  },
                ],
                staticClass: "label ellipsis",
              },
              [_vm._v(_vm._s(_vm.name))]
            ),
            _c("input", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isListEditing,
                  expression: "isListEditing",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "フォルダ名を入力",
                maxlength: 20,
              },
              domProps: { value: _vm.name },
              on: { input: _vm.onInput },
            }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "align-center trailing",
            attrs: { slot: "trailing" },
            slot: "trailing",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isListEditing,
                    expression: "!isListEditing",
                  },
                ],
                staticClass: "folder-item-info",
              },
              [
                _c("div", { staticClass: "folder-count" }, [
                  _vm._v(_vm._s(_vm.count)),
                ]),
                _c("div", { staticClass: "arrow" }, [_vm._v("＞")]),
              ]
            ),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isListEditing,
                  expression: "isListEditing",
                },
              ],
              staticClass: "drag-icon",
              class: `${_vm.handleClassName}`,
              attrs: { src: require("@/assets/img/icon/drag.png") },
            }),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }